<div class="container px-4 py-5">
    <main id="main">

        <div class="row g-5">
            <div class="col-md-12 col-lg-12">
                <h2 class="pb-2 border-bottom"><span *ngIf="this.accion == 'crear'">Crear</span><span
                        *ngIf="this.accion == 'modificar'">Modificar</span> Partido</h2>
                <div class="row g-3" *ngIf="this.partido">
                    <div class="col-sm-6">
                        <label for="Local" class="form-label">Local</label>
                        <select class="form-select" name="Local" required="" (change)="this.seleccionarEquipoLocal($event.target.value)">
                            <option *ngFor="let equipo of this.equiposLocalPosibles" value="{{ equipo.id }}">{{equipo.alias}}</option>
                        </select>
                        <!-- <div class="invalid-feedback">
                            Un apellido valido es requerido.
                        </div> -->
                    </div>

                    <div class="col-sm-6 alert alert-warning" *ngIf="!this.partido.visita.id">
                        <label for="Visita" class="form-label">Visita</label>
                        <div class="input-group mb-3">
                            <select class="form-select form-select-sm" name="EquipoVisitaRegion"
                                placeholder="Seleccione la región" [(ngModel)]="this.equipoVisitaRegionSeleccionada"
                                (change)="this.filtrarComunasEquipoVisita($event.target.value)">
                                <option *ngFor="let region of equipoVisitaRegiones" value="{{ region.id }}"
                                    [selected]="this.equipoVisitaRegionSeleccionada == region.id">{{ region.nombre }}
                                </option>
                            </select>
                            <select class="form-select form-select-sm" name="equipoVisitaComuna"
                                placeholder="Seleccione la comuna" [(ngModel)]="this.equipoVisitaComunaSeleccionada">
                                <option *ngFor="let comuna of equipoVisitaComunas; index as i" value="{{ comuna.id }}"
                                    [selected]="this.equipoVisitaComunaSeleccionada == comuna.id">{{ comuna.nombre }}
                                </option>
                            </select>
                            <button class="btn btn-outline-primary" type="button"
                                (click)="this.buscarEquipoVisitaPorComuna()">Buscar</button>
                        </div>
                        <!-- <div class="input-group mb-3">
                            <input type="text" class="form-control" name="txtVisita"
                                placeholder="Ingrese alguna palabra..." [(ngModel)]="this.textoBusquedaVisita"
                                required="">
                            <button class="btn btn-outline-secondary" type="button"
                                (click)="this.buscarVisita()">Buscar</button>
                        </div> -->
                        <table class="table table-sm">
                            <tbody>
                                <tr *ngFor="let visita of this.equiposVisitaPosibles">
                                    <td>{{ visita.alias }}</td>
                                    <td><button class="btn btn-secondary"
                                            style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                            type="button" (click)="this.partido.visita = visita">Desafiar</button></td>
                                </tr>
                            </tbody>
                        </table>
                        <p class="p-0" *ngIf="this.equiposVisitaPosibles && this.equiposVisitaPosibles.length < 1">No
                            hay resultados</p>
                    </div>
                    <div class="col-sm-6" *ngIf="this.partido.visita.id">
                        <label for="Visita" class="form-label">Visita</label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" name="Visita" placeholder="'Salas', 'Zamorano', etc"
                                [(ngModel)]="this.partido.visita.nombre" required="" disabled>
                            <button class="btn btn-outline-secondary" type="button"
                                (click)="this.partido.visita = {}; this.equiposVisitaPosibles = []">Cambiar</button>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <label for="Fecha" class="form-label">Fecha</label>
                        <div class="input-group mb-3">
                            <input type="date" [(value)]="this.fechaSeleccionada" required pattern="\d{2}-\d{2}-\d{4}"
                                (change)="this.fechaCambiada($event)" class="form-control">
                            <div class="invalid-feedback">
                                Un apellido valido es requerido.
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <label for="Horario" class="form-label">Horario</label>
                        <app-input-horario [(value)]="this.horario"
                            (valueChange)="this.horarioCambiado()"></app-input-horario>
                        <div class="invalid-feedback">
                            Un apellido valido es requerido.
                        </div>
                    </div>

                    <div class="col-sm-6 alert alert-warning" *ngIf="!this.partido.estadio.id">
                        <label for="Recinto" class="form-label">Recinto</label>

                        <div class="input-group mb-3">
                            <select class="form-select form-select-sm" name="RecintoRegion"
                                placeholder="Seleccione la región" [(ngModel)]="this.recintosRegionSeleccionada"
                                (change)="this.filtrarComunasRecinto($event.target.value)">
                                <option *ngFor="let region of recintosRegiones" value="{{ region.id }}"
                                    [selected]="this.recintosRegionSeleccionada == region.id">{{ region.nombre }}
                                </option>
                            </select>
                            <select class="form-select form-select-sm" name="RecintoComuna"
                                placeholder="Seleccione la comuna" [(ngModel)]="this.recintosComunaSeleccionada">
                                <option *ngFor="let comuna of recintosComunas; index as i" value="{{ comuna.id }}"
                                    [selected]="this.recintosComunaSeleccionada == comuna.id">{{ comuna.nombre }}
                                </option>
                            </select>
                            <button class="btn btn-outline-primary" type="button"
                                (click)="this.buscarRecinto()">Buscar</button>
                        </div>
                        <table class="table table-sm" *ngIf="this.recintosPosibles">
                            <tbody>
                                <tr *ngFor="let recinto of this.recintosPosibles">
                                    <td>{{ recinto.nombre }}</td>
                                    <td><button class="btn btn-lg btn-secondary"
                                            style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                            type="button" (click)="this.partido.estadio = recinto">Seleccionar</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p class="p-0" *ngIf="this.recintosPosibles && this.recintosPosibles.length < 1">No hay
                            resultados</p>
                    </div>
                    <div class="col-sm-6" *ngIf="this.partido.estadio.id">
                        <label for="Recinto" class="form-label">Recinto</label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" name="Recinto"
                                [(ngModel)]="this.partido.estadio.nombre" required="" disabled>
                            <button class="btn btn-outline-secondary" type="button"
                                (click)="this.partido.estadio = {}; this.recintosPosibles = null">Cambiar</button>
                        </div>
                    </div>


                    <div class="col-sm-6">
                        <label for="jugadoresPorEquipo" class="form-label">Jugadores Por Equipo</label>
                        <select class="form-select" name="jugadoresPorEquipo" required=""
                            [(ngModel)]="this.partido.jugadoresPorEquipo">
                            <option *ngFor="let cantJugadores of this.opcionesCantidadJugadoresPosibles"
                                [ngValue]="cantJugadores">{{ cantJugadores }}</option>
                        </select>
                        <div class="invalid-feedback">
                            Un valor para la cantidad de jugadores por equipo es requerido
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <label for="superficie" class="form-label">Superficie</label>
                        <select class="form-select" name="superficie" required="" [(ngModel)]="this.partido.superficie">
                            <option *ngFor="let superficie of this.superficiesPosibles" [ngValue]="superficie.key">
                                {{superficie.value}}</option>
                        </select>
                        <div class="invalid-feedback">
                            Un apellido valido es requerido.
                        </div>
                    </div>

                    <div class="card text-bg-danger" *ngIf="this.mensajesError && this.mensajesError.length > 0">
                        <div class="card-header">Ups!</div>
                        <div class="card-body">
                            <h5 class="card-title">Encontramos algunos problemas</h5>
                            <ul>
                                <li *ngFor="let mensajeError of this.mensajesError">{{ mensajeError }}</li>
                            </ul>
                        </div>
                    </div>

                    <button class="w-100 btn btn-warning btn-lg" (click)="guardar()">Guardar</button>
                </div>
            </div>
        </div>
    </main>
</div>