<div class="container px-4 py-5" *ngIf="!this.perfil">
    <main>
        <div class="spinner-border text-success" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </main>
</div>
<ng-container *ngIf="this.perfil">
    <div class="container px-4 py-5">
        <main>
            <h2 class="pb-2 border-bottom"><span style="color: gray">Mi </span>
                Perfil
                <button [routerLink]="['/jugador/editor', 'modificar', this.perfil.id]" class="btn btn-sm btn-warning"
                    style="float: right;"><i class="fa-solid fa-square-pen"></i> Modificar</button>
            </h2>

            <div class="row">
                <div class="col-sm-6 p-4 alias"><span class="small">nombre</span><br><i class="fa fa-id-card"></i>
                    {{ this.perfil.nombre }} {{ this.perfil.apellido}}
                </div>
                <div class="col-sm-6 p-4 alias"><span class="small">correo</span><br><i class="fa fa-envelope"></i>
                    {{ this.perfil.email }}</div>

                <div class="col-sm-6 col-md-4 p-4 alias"><span class="small">alias</span><br><i class="fa fa-user"></i>
                    "{{ this.perfil.alias }}"</div>

                <div class="col-sm-6 col-md-4 p-4 posicion">
                    <span class="small">posición</span><br>
                    <app-posicion-miniatura [posicion]="perfil.posicion"></app-posicion-miniatura>
                </div>

                <div class="col-sm-6 col-md-4  p-4 ubicacion">
                    <span class="small">ubicación</span><br>
                    <i class="fa fa-location-pin"></i> {{ obtenerUbicacion(this.perfil.ubicacion.comuna).nombre }}
                </div>

                <div class="col-sm-6 col-md-4 p-4 edad">
                    <span class="small">edad</span><br>
                    <i class="fa fa-calendar"></i> {{
                    calcularEdad(this.perfil.fechaNacimiento)}} años
                </div>

                <div class="col-sm-6 col-md-4 p-4 estatura">
                    <span class="small">estatura</span><br>
                    <i class="fa fa-ruler-vertical"></i> {{ this.perfil.estatura }} cm.
                </div>

                <div class="col-sm-6 col-md-4 p-4 peso">
                    <span class="small">peso</span><br>
                    <i class="fa fa-weight-scale"></i> {{ this.perfil.peso }} kg.
                </div>
            </div>

            <div class="row pt-4" *ngIf="this.equipos">
                <h2 class="pb-4 border-bottom"><span style="color: gray">Mis </span> Equipos
                    <button [routerLink]="['/equipo/editor', 'crear', 0]" class="btn btn-sm btn-warning"
                        style="float: right;"><i class="fa-solid fa-square-plus"></i> Crear</button>

                </h2>
                <div *ngFor="let equipo of this.equipos" class="col-sm-12 col-md-6 col-lg-4 p-2">
                    <div class="shadow-sm p-3 mb-5 bg-white rounded">

                        <div class="dropdown">
                            <button style="float: right" class="btn btn-sm btn-secondary dropdown-toggle" type="button"
                                data-bs-toggle="dropdown">
                                <i class="fa-solid fa-ellipsis"></i>
                            </button>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" [routerLink]="['/equipo', equipo.id]"><i class="fa fa-circle-info"></i> Ver equipo</a></li>
                                <li><a class="dropdown-item" *ngIf="!this.esCapitan(equipo)" data-bs-toggle="modal" [attr.data-bs-target]="'#renunciarModal-' + equipo.id"><i class="fa-solid fa-person-walking-dashed-line-arrow-right"></i> Renunciar</a></li>
                            </ul>
                        </div>

                        <span class="equipo-alias"><a [routerLink]="['/equipo', equipo.id]">{{ equipo.alias
                                }}</a></span><br>
                        <span class="border border-secondary rounded"
                            style="background-color: {{ equipo.color }};">&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;
                        <span class="border border-secondary rounded"
                            style="background-color: {{ equipo.colorAlternativo }};">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span class="equipo-ubicacion">&nbsp;&nbsp;|&nbsp;{{
                            obtenerUbicacion(equipo.ubicacion.comuna).nombre }}</span>
                        <span class="equipo-esCapitan" *ngIf="this.esCapitan(equipo)">&nbsp;|&nbsp;<b>Eres
                                capitán</b></span><br>

                        <!-- Modal Renunciar al Equipo -->
                        <div class="modal fade" id="renunciarModal-{{equipo.id}}" tabindex="-1">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h1 class="modal-title fs-5">Confirme su renuncia</h1>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        ¿Esta seguro que desea renunciar al equipo <b>{{equipo.nombre}}</b>?.
                                        Si quisiera volver al equipo, necesitará ser recontratado por un capitan del mismo.
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" (click)="this.renunciar(equipo)" class="btn btn-warning" data-bs-dismiss="modal">Renunciar</button>
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Volver</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="pt-4 pb-4" *ngIf="this.equipos && this.equipos.length == 0">Ud aún no tiene equipos.</p>
            </div>
            <div class="row" *ngIf="!this.equipos">
                <div class="spinner-border text-success" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </main>
    </div>
</ng-container>