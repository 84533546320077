import { Injectable } from '@angular/core';
import { environment } from '../environments/environment'
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SesionService } from './servicio.sesion';

@Injectable()
export class PartidosService {
    urlApi: string;

    constructor (
        private http: HttpClient,
        private servicioSesiones: SesionService
    ) {
        this.urlApi = environment.apiLocation;
    }

    private headersGet() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.servicioSesiones.obtenerSesion().token
        }
          
        return new HttpHeaders(headerDict);
    }

    private headersAnonimos() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type'
        }
          
        return new HttpHeaders(headerDict);
    }

    private headersPost() : any
    {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'token': this.servicioSesiones.obtenerSesion().token
          }
          
          return new HttpHeaders(headerDict);
    }

    public getPartido(id: string) : Observable<any> {
        var options =  {
            headers: this.headersAnonimos()
        };
        return this.http.get(`${this.urlApi}/partido/${id}`, options);
    }

    public getPartidos(filtro: string, pagina: number, largoPagina: number) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        return this.http.post(`${this.urlApi}/partido/Buscar`, { keywords: filtro.split(' '), largoPagina: largoPagina, pagina: pagina }, options);
    }

    public getMany(ids: Array<string>) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        let url = `${this.urlApi}/partido/getmany/?ids=${ids.join(",")}`;
        return this.http.get(url, options);
    }

    public getEstadisticasPartido(id: string) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        return this.http.get(`${this.urlApi}/partido/EstadisticasPartido/${id}`, options);
    }

    public getEstadisticasJugadores(id: string) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        return this.http.get(`${this.urlApi}/partido/EstadisticasJugadores/${id}`, options);
    }

    public crear(partido: any) : Observable<any> {
        var options =  {
            headers: this.headersPost()
        };
        
        return this.http.put(`${this.urlApi}/partido`, partido, options);
    }

    public actualizar(partido: any) : Observable<any> {
        var options =  {
            headers: this.headersPost()
        };
        
        return this.http.post(`${this.urlApi}/partido`, partido, options);
    }

    public invitar(invitacionPartido: any) : Observable<any> {
        var options =  {
            headers: this.headersPost()
        };
        
        return this.http.post(`${this.urlApi}/partido/invitar`, invitacionPartido, options);
    }

    public responderInvitacion(invitacionPartido: any) : Observable<any> {
        var options =  {
            headers: this.headersPost()
        };
        
        return this.http.post(`${this.urlApi}/partido/responder`, invitacionPartido, options);
    }

    public actualizarInvitacion(invitacionPartido: any) : Observable<any> {
        var options =  {
            headers: this.headersPost()
        };
        
        return this.http.post(`${this.urlApi}/partido/ActualizarInvitacion`, invitacionPartido, options);
    }

    public eliminarInvitacion(invitacionPartido: any) : Observable<any> {
        var options =  {
            headers: this.headersPost()
        };
        
        return this.http.post(`${this.urlApi}/partido/eliminarinvitacion`, invitacionPartido, options);
    }

    public finalizar(partido: any) : Observable<any> {
        var options =  {
            headers: this.headersPost()
        };
        
        return this.http.post(`${this.urlApi}/partido/finalizar`, partido, options);
    }

    public eliminar(partido: any) : Observable<any> {
        var options =  {
            headers: this.headersPost(),
            body: partido
        };
        return this.http.delete(`${this.urlApi}/partido`, options);
    }

    public getPartidosPorEquipo(id: string) : Observable<any> {
        var options =  {
            headers: this.headersGet()
        };
        return this.http.get(`${this.urlApi}/partido/PorEquipo/${id}`, options);
    }

    public registrarAccion(accion: any) : Observable<any>  {
        var options =  {
            headers: this.headersPost()
        };
        
        return this.http.post(`${this.urlApi}/partido/accion`, accion, options);
    }
    
    public eliminarAccion(accion: any) : Observable<any>  {
        var options =  {
            headers: this.headersPost()
        };
        
        return this.http.post(`${this.urlApi}/partido/RemoverAccion`, accion, options);
    }

    public comentar(comentario: any) : Observable<any>  {
        var options =  {
            headers: this.headersPost()
        };
        
        return this.http.post(`${this.urlApi}/partido/Comentar`, comentario, options);
    }

    public removerComentario(comentario: any) : Observable<any>  {
        var options =  {
            headers: this.headersPost()
        };
        
        return this.http.post(`${this.urlApi}/partido/removerComentario`, comentario, options);
    }
}