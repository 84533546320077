import { Injectable } from '@angular/core';
@Injectable()
export class FormacionesService {
    private formaciones: Array<any> = [
        { nombre: "5-2-2-1", cantJugadores: 11, posiciones: [
            {index: 0,  heightPerc: 10, widthPerc: 50, obs: "portero" },
            
            {index: 1,  heightPerc: 25, widthPerc: 16, obs: "latDerecho" },
            {index: 2,  heightPerc: 25, widthPerc: 32, obs: "centralIzquierdo" },
            {index: 3,  heightPerc: 25, widthPerc: 48, obs: "centralDerecho" },
            {index: 4,  heightPerc: 25, widthPerc: 64, obs: "latIzquierdo" },
            {index: 5,  heightPerc: 25, widthPerc: 80, obs: "medioIzquierdo" },

            {index: 6,  heightPerc: 45, widthPerc: 30, obs: "medioCentral" },
            {index: 7,  heightPerc: 45, widthPerc: 70, obs: "medioDerecho" },

            {index: 8,  heightPerc: 65, widthPerc: 30, obs: "atacanteDerecho" },
            {index: 9,  heightPerc: 65, widthPerc: 70, obs: "atacanteCentro" },

            {index: 10, heightPerc: 85, widthPerc: 50, obs: "atacanteDer" }


        ] },
        { nombre: "5–3–2", cantJugadores: 11, posiciones: [

            {index: 0,  heightPerc: 10, widthPerc: 50, obs: "portero" },
            
            {index: 1,  heightPerc: 30, widthPerc: 16, obs: "latDerecho" },
            {index: 2,  heightPerc: 30, widthPerc: 32, obs: "centralIzquierdo" },
            {index: 3,  heightPerc: 30, widthPerc: 48, obs: "centralDerecho" },
            {index: 4,  heightPerc: 30, widthPerc: 64, obs: "latIzquierdo" },
            {index: 5,  heightPerc: 30, widthPerc: 80, obs: "medioIzquierdo" },

            {index: 6,  heightPerc: 50, widthPerc: 30, obs: "medioCentral" },
            {index: 7,  heightPerc: 50, widthPerc: 50, obs: "medioDerecho" },
            {index: 8,  heightPerc: 50, widthPerc: 70, obs: "atacanteDerecho" },

            {index: 9,  heightPerc: 70, widthPerc: 25, obs: "atacanteCentro" },
            {index: 10, heightPerc: 70, widthPerc: 75, obs: "atacanteDer" }


        ] },
        { nombre: "5–4–1", cantJugadores: 11, posiciones: [

            {index: 0,  heightPerc: 10, widthPerc: 50, obs: "portero" },
            
            {index: 1,  heightPerc: 30, widthPerc: 16, obs: "latDerecho" },
            {index: 2,  heightPerc: 30, widthPerc: 32, obs: "centralIzquierdo" },
            {index: 3,  heightPerc: 30, widthPerc: 48, obs: "centralDerecho" },
            {index: 4,  heightPerc: 30, widthPerc: 64, obs: "latIzquierdo" },
            {index: 5,  heightPerc: 30, widthPerc: 80, obs: "medioIzquierdo" },

            {index: 6,  heightPerc: 50, widthPerc: 20, obs: "medioCentral" },
            {index: 7,  heightPerc: 50, widthPerc: 40, obs: "medioDerecho" },
            {index: 8,  heightPerc: 50, widthPerc: 60, obs: "atacanteDerecho" },
            {index: 9,  heightPerc: 50, widthPerc: 80, obs: "atacanteCentro" },

            {index: 10, heightPerc: 70, widthPerc: 50, obs: "atacanteDer" }


        ] },
        { nombre: "4–2–4", cantJugadores: 11, posiciones: [

            {index: 0,  heightPerc: 10, widthPerc: 50, obs: "portero" },
            
            {index: 1,  heightPerc: 30, widthPerc: 20, obs: "latDerecho" },
            {index: 2,  heightPerc: 30, widthPerc: 40, obs: "centralIzquierdo" },
            {index: 3,  heightPerc: 30, widthPerc: 60, obs: "centralDerecho" },
            {index: 4,  heightPerc: 30, widthPerc: 80, obs: "latIzquierdo" },
            
            {index: 5,  heightPerc: 50, widthPerc: 30, obs: "medioIzquierdo" },
            {index: 6,  heightPerc: 50, widthPerc: 70, obs: "medioCentral" },

            {index: 7,  heightPerc: 70, widthPerc: 20, obs: "medioDerecho" },
            {index: 8,  heightPerc: 70, widthPerc: 40, obs: "atacanteDerecho" },
            {index: 9,  heightPerc: 70, widthPerc: 60, obs: "atacanteCentro" },
            {index: 10, heightPerc: 70, widthPerc: 80, obs: "atacanteDer" }

        ] },
        { nombre: "4–4–2", cantJugadores: 11, posiciones: [

            {index: 0,  heightPerc: 10, widthPerc: 50, obs: "portero" },
            
            {index: 1,  heightPerc: 30, widthPerc: 20, obs: "latDerecho" },
            {index: 2,  heightPerc: 30, widthPerc: 40, obs: "centralIzquierdo" },
            {index: 3,  heightPerc: 30, widthPerc: 60, obs: "centralDerecho" },
            {index: 4,  heightPerc: 30, widthPerc: 80, obs: "latIzquierdo" },
            
            {index: 5,  heightPerc: 50, widthPerc: 20, obs: "medioIzquierdo" },
            {index: 6,  heightPerc: 50, widthPerc: 40, obs: "medioCentral" },
            {index: 7,  heightPerc: 50, widthPerc: 60, obs: "medioDerecho" },
            {index: 8,  heightPerc: 50, widthPerc: 80, obs: "atacanteDerecho" },

            {index: 9,  heightPerc: 70, widthPerc: 25, obs: "atacanteCentro" },
            {index: 10, heightPerc: 70, widthPerc: 75, obs: "atacanteDer" }

        ] },
        { nombre: "4–4–1–1", cantJugadores: 11, posiciones: [

            {index: 0,  heightPerc: 10, widthPerc: 50, obs: "portero" },
            
            {index: 1,  heightPerc: 30, widthPerc: 20, obs: "latDerecho" },
            {index: 2,  heightPerc: 30, widthPerc: 40, obs: "centralIzquierdo" },
            {index: 3,  heightPerc: 30, widthPerc: 60, obs: "centralDerecho" },
            {index: 4,  heightPerc: 30, widthPerc: 80, obs: "latIzquierdo" },
            
            {index: 5,  heightPerc: 50, widthPerc: 20, obs: "medioIzquierdo" },
            {index: 6,  heightPerc: 50, widthPerc: 40, obs: "medioCentral" },
            {index: 7,  heightPerc: 50, widthPerc: 60, obs: "medioDerecho" },
            {index: 8,  heightPerc: 50, widthPerc: 80, obs: "atacanteDerecho" },

            {index: 9,  heightPerc: 65, widthPerc: 50, obs: "atacanteCentro" },

            {index: 10, heightPerc: 80, widthPerc: 50, obs: "atacanteDer" }

        ] },
        { nombre: "4–1–3–2", cantJugadores: 11, posiciones: [

            {index: 0,  heightPerc: 10, widthPerc: 50, obs: "portero" },
            
            {index: 1,  heightPerc: 30, widthPerc: 20, obs: "latDerecho" },
            {index: 2,  heightPerc: 30, widthPerc: 40, obs: "centralIzquierdo" },
            {index: 3,  heightPerc: 30, widthPerc: 60, obs: "centralDerecho" },
            {index: 4,  heightPerc: 30, widthPerc: 80, obs: "latIzquierdo" },
            
            {index: 5,  heightPerc: 45, widthPerc: 50, obs: "medioIzquierdo" },
            
            {index: 6,  heightPerc: 60, widthPerc: 30, obs: "medioCentral" },
            {index: 7,  heightPerc: 60, widthPerc: 50, obs: "medioDerecho" },
            {index: 8,  heightPerc: 60, widthPerc: 70, obs: "atacanteDerecho" },

            {index: 9,  heightPerc: 80, widthPerc: 30, obs: "atacanteCentro" },
            {index: 10, heightPerc: 80, widthPerc: 70, obs: "atacanteDer" }


        ] },
        { nombre: "4–3–3", cantJugadores: 11, posiciones: [

            {index: 0, heightPerc: 10, widthPerc: 50, obs: "portero" },

            {index: 1, heightPerc: 30, widthPerc: 20, obs: "latDerecho" },
            {index: 2, heightPerc: 30, widthPerc: 40, obs: "centralIzquierdo" },
            {index: 3, heightPerc: 30, widthPerc: 60, obs: "centralDerecho" },
            {index: 4, heightPerc: 30, widthPerc: 80, obs: "latIzquierdo" },

            {index: 5, heightPerc: 50, widthPerc: 30, obs: "medioIzquierdo" },
            {index: 6, heightPerc: 50, widthPerc: 50, obs: "medioCentral" },
            {index: 7, heightPerc: 50, widthPerc: 70, obs: "medioDerecho" },

            {index: 8, heightPerc: 70, widthPerc: 20, obs: "atacanteDerecho" },
            {index: 9, heightPerc: 70, widthPerc: 50, obs: "atacanteCentro" },
            {index: 10, heightPerc: 70, widthPerc: 80, obs: "atacanteDer" }

        ] },
        { nombre: "4–3–1–2", cantJugadores: 11, posiciones: [

            {index: 0, heightPerc: 10, widthPerc: 50, obs: "portero" },

            {index: 1, heightPerc: 30, widthPerc: 20, obs: "latDerecho" },
            {index: 2, heightPerc: 30, widthPerc: 40, obs: "centralIzquierdo" },
            {index: 3, heightPerc: 30, widthPerc: 60, obs: "centralDerecho" },
            {index: 4, heightPerc: 30, widthPerc: 80, obs: "latIzquierdo" },

            {index: 5, heightPerc: 45, widthPerc: 30, obs: "medioIzquierdo" },
            {index: 6, heightPerc: 45, widthPerc: 50, obs: "medioCentral" },
            {index: 7, heightPerc: 45, widthPerc: 70, obs: "medioDerecho" },
            
            {index: 8, heightPerc: 60, widthPerc: 50, obs: "atacanteDerecho" },

            {index: 9,  heightPerc: 75, widthPerc: 30, obs: "atacanteCentro" },
            {index: 10, heightPerc: 75, widthPerc: 70, obs: "atacanteDer" }

        ] },
        { nombre: "4–1–2–3", cantJugadores: 11, posiciones: [

            {index: 0, heightPerc: 10, widthPerc: 50, obs: "portero" },

            {index: 1, heightPerc: 30, widthPerc: 20, obs: "latDerecho" },
            {index: 2, heightPerc: 30, widthPerc: 40, obs: "centralIzquierdo" },
            {index: 3, heightPerc: 30, widthPerc: 60, obs: "centralDerecho" },
            {index: 4, heightPerc: 30, widthPerc: 80, obs: "latIzquierdo" },

            {index: 5, heightPerc: 45, widthPerc: 50, obs: "medioIzquierdo" },

            {index: 6, heightPerc: 60, widthPerc: 30, obs: "medioCentral" },
            {index: 7, heightPerc: 60, widthPerc: 70, obs: "medioDerecho" },
            
            {index: 8,  heightPerc: 75, widthPerc: 20, obs: "atacanteDerecho" },
            {index: 9,  heightPerc: 75, widthPerc: 50, obs: "atacanteCentro" },
            {index: 10, heightPerc: 75, widthPerc: 80, obs: "atacanteDer" }

        ] },
        { nombre: "4–3–2–1", cantJugadores: 11, posiciones: [

            {index: 0, heightPerc: 10, widthPerc: 50, obs: "portero" },

            {index: 1, heightPerc: 30, widthPerc: 20, obs: "latDerecho" },
            {index: 2, heightPerc: 30, widthPerc: 40, obs: "centralIzquierdo" },
            {index: 3, heightPerc: 30, widthPerc: 60, obs: "centralDerecho" },
            {index: 4, heightPerc: 30, widthPerc: 80, obs: "latIzquierdo" },

            {index: 5, heightPerc: 45, widthPerc: 30, obs: "medioIzquierdo" },
            {index: 6, heightPerc: 45, widthPerc: 50, obs: "medioCentral" },
            {index: 7, heightPerc: 45, widthPerc: 70, obs: "medioDerecho" },
            
            {index: 8,  heightPerc: 60, widthPerc: 40, obs: "atacanteDerecho" },
            {index: 9,  heightPerc: 60, widthPerc: 60, obs: "atacanteCentro" },

            {index: 10, heightPerc: 75, widthPerc: 50, obs: "atacanteDer" }

        ] },
        { nombre: "4–2–3–1", cantJugadores: 11, posiciones: [

            {index: 0, heightPerc: 10, widthPerc: 50, obs: "portero" },

            {index: 1, heightPerc: 30, widthPerc: 20, obs: "latDerecho" },
            {index: 2, heightPerc: 30, widthPerc: 40, obs: "centralIzquierdo" },
            {index: 3, heightPerc: 30, widthPerc: 60, obs: "centralDerecho" },
            {index: 4, heightPerc: 30, widthPerc: 80, obs: "latIzquierdo" },

            {index: 5, heightPerc: 45, widthPerc: 30, obs: "medioIzquierdo" },
            {index: 6, heightPerc: 45, widthPerc: 70, obs: "medioCentral" },

            {index: 7, heightPerc: 60, widthPerc: 20, obs: "medioDerecho" },
            {index: 8,  heightPerc: 60, widthPerc: 50, obs: "atacanteDerecho" },
            {index: 9,  heightPerc: 60, widthPerc: 80, obs: "atacanteCentro" },

            {index: 10, heightPerc: 75, widthPerc: 50, obs: "atacanteDer" }

        ] },
        { nombre: "4–2–2–2", cantJugadores: 11, posiciones: [

            {index: 0, heightPerc: 10, widthPerc: 50, obs: "portero" },

            {index: 1, heightPerc: 30, widthPerc: 20, obs: "latDerecho" },
            {index: 2, heightPerc: 30, widthPerc: 40, obs: "centralIzquierdo" },
            {index: 3, heightPerc: 30, widthPerc: 60, obs: "centralDerecho" },
            {index: 4, heightPerc: 30, widthPerc: 80, obs: "latIzquierdo" },

            {index: 5, heightPerc: 45, widthPerc: 30, obs: "medioIzquierdo" },
            {index: 6, heightPerc: 45, widthPerc: 70, obs: "medioCentral" },

            {index: 7, heightPerc: 60, widthPerc: 30, obs: "medioDerecho" },
            {index: 8, heightPerc: 60, widthPerc: 70, obs: "atacanteDerecho" },

            {index: 9, heightPerc: 75, widthPerc: 30, obs: "atacanteCentro" },
            {index: 10,heightPerc: 75, widthPerc: 70, obs: "atacanteDer" }


        ] },
        { nombre: "4–2–1–3", cantJugadores: 11, posiciones: [

            {index: 0, heightPerc: 10, widthPerc: 50, obs: "portero" },

            {index: 1, heightPerc: 30, widthPerc: 20, obs: "latDerecho" },
            {index: 2, heightPerc: 30, widthPerc: 40, obs: "centralIzquierdo" },
            {index: 3, heightPerc: 30, widthPerc: 60, obs: "centralDerecho" },
            {index: 4, heightPerc: 30, widthPerc: 80, obs: "latIzquierdo" },

            {index: 5, heightPerc: 45, widthPerc: 30, obs: "medioIzquierdo" },
            {index: 6, heightPerc: 45, widthPerc: 70, obs: "medioCentral" },

            {index: 7, heightPerc: 60, widthPerc: 50, obs: "medioDerecho" },

            {index: 8, heightPerc: 75, widthPerc: 30, obs: "atacanteDerecho" },
            {index: 9, heightPerc: 75, widthPerc: 50, obs: "atacanteCentro" },
            {index: 10,heightPerc: 75, widthPerc: 70, obs: "atacanteDer" }

        ] },
        { nombre: "4–5–1", cantJugadores: 11, posiciones: [

            {index: 0, heightPerc: 10, widthPerc: 50, obs: "portero" },

            {index: 1, heightPerc: 30, widthPerc: 20, obs: "latDerecho" },
            {index: 2, heightPerc: 30, widthPerc: 40, obs: "centralIzquierdo" },
            {index: 3, heightPerc: 30, widthPerc: 60, obs: "centralDerecho" },
            {index: 4, heightPerc: 30, widthPerc: 80, obs: "latIzquierdo" },

            {index: 5, heightPerc: 50, widthPerc: 16, obs: "medioIzquierdo" },
            {index: 6, heightPerc: 50, widthPerc: 32, obs: "medioCentral" },
            {index: 7, heightPerc: 50, widthPerc: 48, obs: "medioDerecho" },
            {index: 8, heightPerc: 50, widthPerc: 64, obs: "atacanteDerecho" },
            {index: 9, heightPerc: 50, widthPerc: 80, obs: "atacanteCentro" },

            {index: 10,heightPerc: 70, widthPerc: 50, obs: "atacanteDer" }

        ] },
        { nombre: "4–6–0", cantJugadores: 11, posiciones: [

            {index: 0, heightPerc: 10, widthPerc: 50, obs: "portero" },

            {index: 1, heightPerc: 30, widthPerc: 20, obs: "latDerecho" },
            {index: 2, heightPerc: 30, widthPerc: 40, obs: "centralIzquierdo" },
            {index: 3, heightPerc: 30, widthPerc: 60, obs: "centralDerecho" },
            {index: 4, heightPerc: 30, widthPerc: 80, obs: "latIzquierdo" },

            {index: 5, heightPerc: 50, widthPerc: 10, obs: "medioIzquierdo" },
            {index: 6, heightPerc: 50, widthPerc: 25, obs: "medioCentral" },
            {index: 7, heightPerc: 50, widthPerc: 40, obs: "medioDerecho" },
            {index: 8, heightPerc: 50, widthPerc: 60, obs: "atacanteDerecho" },
            {index: 9, heightPerc: 50, widthPerc: 75, obs: "atacanteCentro" },
            {index: 10,heightPerc: 50, widthPerc: 90, obs: "atacanteDer" }

        ] },
        { nombre: "3–3–4", cantJugadores: 11, posiciones: [

            {index: 0, heightPerc: 10, widthPerc: 50, obs: "portero" },

            {index: 1, heightPerc: 30, widthPerc: 20, obs: "latDerecho" },
            {index: 2, heightPerc: 30, widthPerc: 50, obs: "centralIzquierdo" },
            {index: 3, heightPerc: 30, widthPerc: 80, obs: "centralDerecho" },

            {index: 4, heightPerc: 50, widthPerc: 30, obs: "latIzquierdo" },
            {index: 5, heightPerc: 50, widthPerc: 50, obs: "medioIzquierdo" },
            {index: 6, heightPerc: 50, widthPerc: 70, obs: "medioCentral" },

            {index: 7, heightPerc: 70, widthPerc: 20, obs: "medioDerecho" },
            {index: 8, heightPerc: 70, widthPerc: 40, obs: "atacanteDerecho" },
            {index: 9, heightPerc: 70, widthPerc: 60, obs: "atacanteCentro" },
            {index: 10, heightPerc: 70, widthPerc: 80, obs: "atacanteDer" }

        ] },
        { 
            nombre: "3-4-3", cantJugadores: 11, posiciones: 
            [
                {index: 0, heightPerc: 10, widthPerc: 50, obs: "portero" },

                {index: 1, heightPerc: 30, widthPerc: 20, obs: "latDerecho" },
                {index: 2, heightPerc: 30, widthPerc: 50, obs: "centralIzquierdo" },
                {index: 3, heightPerc: 30, widthPerc: 80, obs: "centralDerecho" },

                {index: 4, heightPerc: 50, widthPerc: 20, obs: "latIzquierdo" },
                {index: 5, heightPerc: 50, widthPerc: 40, obs: "medioIzquierdo" },
                {index: 6, heightPerc: 50, widthPerc: 60, obs: "medioCentral" },
                {index: 7, heightPerc: 50, widthPerc: 80, obs: "medioDerecho" },

                {index: 8, heightPerc: 70, widthPerc: 20, obs: "atacanteDerecho" },
                {index: 9, heightPerc: 70, widthPerc: 50, obs: "atacanteCentro" },
                {index: 10, heightPerc: 70, widthPerc: 80, obs: "atacanteDer" }
            ] 
        },
        { nombre: "3–5–2", cantJugadores: 11, posiciones: [

            {index: 0, heightPerc: 10, widthPerc: 50, obs: "portero" },

            {index: 1, heightPerc: 30, widthPerc: 20, obs: "latDerecho" },
            {index: 2, heightPerc: 30, widthPerc: 50, obs: "centralIzquierdo" },
            {index: 3, heightPerc: 30, widthPerc: 80, obs: "centralDerecho" },

            {index: 4, heightPerc: 50, widthPerc: 16, obs: "latIzquierdo" },
            {index: 5, heightPerc: 50, widthPerc: 32, obs: "medioIzquierdo" },
            {index: 6, heightPerc: 50, widthPerc: 48, obs: "medioCentral" },
            {index: 7, heightPerc: 50, widthPerc: 64, obs: "medioDerecho" },
            {index: 8, heightPerc: 50, widthPerc: 80, obs: "atacanteDerecho" },

            {index: 9, heightPerc: 70, widthPerc: 40, obs: "atacanteCentro" },
            {index: 10, heightPerc: 70, widthPerc: 60, obs: "atacanteDer" }

        ] },
        { nombre: "3–2–4–1", cantJugadores: 11, posiciones: [

            {index: 0, heightPerc: 10, widthPerc: 50, obs: "portero" },

            {index: 1, heightPerc: 25, widthPerc: 20, obs: "latDerecho" },
            {index: 2, heightPerc: 25, widthPerc: 50, obs: "centralIzquierdo" },
            {index: 3, heightPerc: 25, widthPerc: 80, obs: "centralDerecho" },

            {index: 4, heightPerc: 40, widthPerc: 25, obs: "latIzquierdo" },
            {index: 5, heightPerc: 40, widthPerc: 75, obs: "medioIzquierdo" },

            {index: 6, heightPerc: 60, widthPerc: 20, obs: "medioCentral" },
            {index: 7, heightPerc: 60, widthPerc: 40, obs: "medioDerecho" },
            {index: 8, heightPerc: 60, widthPerc: 60, obs: "atacanteDerecho" },
            {index: 9, heightPerc: 60, widthPerc: 80, obs: "atacanteCentro" },

            {index: 10, heightPerc: 80, widthPerc: 50, obs: "atacanteDer" }

        ] },
        { nombre: "3–4–1–2", cantJugadores: 11, posiciones: [

            {index: 0, heightPerc: 10, widthPerc: 50, obs: "portero" },

            {index: 1, heightPerc: 25, widthPerc: 20, obs: "latDerecho" },
            {index: 2, heightPerc: 25, widthPerc: 50, obs: "centralIzquierdo" },
            {index: 3, heightPerc: 25, widthPerc: 80, obs: "centralDerecho" },

            {index: 4, heightPerc: 40, widthPerc: 20, obs: "latIzquierdo" },
            {index: 5, heightPerc: 40, widthPerc: 40, obs: "medioIzquierdo" },
            {index: 6, heightPerc: 40, widthPerc: 60, obs: "medioCentral" },
            {index: 7, heightPerc: 40, widthPerc: 80, obs: "medioDerecho" },

            {index: 8, heightPerc: 60, widthPerc: 50, obs: "atacanteDerecho" },

            {index: 9, heightPerc: 80, widthPerc: 30, obs: "atacanteCentro" },
            {index: 10, heightPerc: 80, widthPerc: 70, obs: "atacanteDer" }

        ] },
        { nombre: "3–6–1", cantJugadores: 11, posiciones: [

            {index: 0, heightPerc: 10, widthPerc: 50, obs: "portero" },

            {index: 1, heightPerc: 30, widthPerc: 20, obs: "latDerecho" },
            {index: 2, heightPerc: 30, widthPerc: 50, obs: "centralIzquierdo" },
            {index: 3, heightPerc: 30, widthPerc: 80, obs: "centralDerecho" },

            {index: 4, heightPerc: 50, widthPerc: 10, obs: "latIzquierdo" },
            {index: 5, heightPerc: 50, widthPerc: 25, obs: "medioIzquierdo" },
            {index: 6, heightPerc: 50, widthPerc: 40, obs: "medioCentral" },
            {index: 7, heightPerc: 50, widthPerc: 60, obs: "medioDerecho" },
            {index: 8, heightPerc: 50, widthPerc: 75, obs: "atacanteDerecho" },
            {index: 9, heightPerc: 50, widthPerc: 90, obs: "atacanteCentro" },

            {index: 10, heightPerc: 70, widthPerc: 50, obs: "atacanteDer" }

        ] },
        { 
            nombre: "3-3-1-3", cantJugadores: 11, posiciones: 
            [
                {index: 0, heightPerc: 10, widthPerc: 50, obs: "portero" },

                {index: 1, heightPerc: 30, widthPerc: 20, obs: "latDerecho" },
                {index: 2, heightPerc: 30, widthPerc: 50, obs: "centralIzquierdo" },
                {index: 3, heightPerc: 30, widthPerc: 80, obs: "centralDerecho" },

                {index: 4, heightPerc: 50, widthPerc: 20, obs: "latIzquierdo" },
                {index: 5, heightPerc: 50, widthPerc: 50, obs: "medioIzquierdo" },
                {index: 6, heightPerc: 50, widthPerc: 80, obs: "medioCentral" },

                {index: 7, heightPerc: 65, widthPerc: 50, obs: "medioDerecho" },

                {index: 8, heightPerc: 80, widthPerc: 20, obs: "atacanteDerecho" },
                {index: 9, heightPerc: 80, widthPerc: 50, obs: "atacanteCentro" },
                {index: 10, heightPerc: 80, widthPerc: 80, obs: "atacanteDer" }
            ] 
        },
        { nombre: "3–3–3–1", cantJugadores: 11, posiciones: [

            {index: 0, heightPerc: 10, widthPerc: 50, obs: "portero" },

            {index: 1, heightPerc: 20, widthPerc: 25, obs: "latDerecho" },
            {index: 2, heightPerc: 20, widthPerc: 50, obs: "centralIzquierdo" },
            {index: 3, heightPerc: 20, widthPerc: 75, obs: "centralDerecho" },

            {index: 4, heightPerc: 40, widthPerc: 25, obs: "latIzquierdo" },
            {index: 5, heightPerc: 40, widthPerc: 50, obs: "medioIzquierdo" },
            {index: 6, heightPerc: 40, widthPerc: 75, obs: "medioCentral" },

            {index: 7, heightPerc: 60, widthPerc: 25, obs: "medioDerecho" },
            {index: 8, heightPerc: 60, widthPerc: 50, obs: "atacanteDerecho" },
            {index: 9, heightPerc: 60, widthPerc: 75, obs: "atacanteCentro" },

            {index: 10, heightPerc: 80, widthPerc: 50, obs: "atacanteDer" }

        ] },
        { nombre: "2–3–5", cantJugadores: 11, posiciones: [

            {index: 0, heightPerc: 10, widthPerc: 50, obs: "portero" },

            {index: 1, heightPerc: 30, widthPerc: 25, obs: "latDerecho" },
            {index: 2, heightPerc: 30, widthPerc: 75, obs: "centralIzquierdo" },

            {index: 3, heightPerc: 50, widthPerc: 30, obs: "centralDerecho" },
            {index: 4, heightPerc: 50, widthPerc: 50, obs: "latIzquierdo" },
            {index: 5, heightPerc: 50, widthPerc: 70, obs: "medioIzquierdo" },

            {index: 6, heightPerc: 70,  widthPerc: 16, obs: "medioCentral" },
            {index: 7, heightPerc: 70,  widthPerc: 32, obs: "medioDerecho" },
            {index: 8, heightPerc: 70,  widthPerc: 48, obs: "atacanteDerecho" },
            {index: 9, heightPerc: 70,  widthPerc: 64, obs: "atacanteCentro" },
            {index: 10, heightPerc: 70, widthPerc: 80, obs: "atacanteDer" }

        ] },
        { nombre: "2–3–2–3", cantJugadores: 11, posiciones: [

            {index: 0, heightPerc: 10, widthPerc: 50, obs: "portero" },

            {index: 1, heightPerc: 25, widthPerc: 25, obs: "latDerecho" },
            {index: 2, heightPerc: 25, widthPerc: 75, obs: "centralIzquierdo" },

            {index: 3, heightPerc: 45, widthPerc: 30, obs: "centralDerecho" },
            {index: 4, heightPerc: 45, widthPerc: 50, obs: "latIzquierdo" },
            {index: 5, heightPerc: 45, widthPerc: 70, obs: "medioIzquierdo" },

            {index: 6, heightPerc: 65,  widthPerc: 25, obs: "medioCentral" },
            {index: 7, heightPerc: 65,  widthPerc: 75, obs: "medioDerecho" },

            {index: 8,  heightPerc: 85,  widthPerc: 30, obs: "atacanteDerecho" },
            {index: 9,  heightPerc: 85,  widthPerc: 50, obs: "atacanteCentro" },
            {index: 10, heightPerc: 85,  widthPerc: 70, obs: "atacanteDer" }

        ] },

    ];

    constructor (
    ) {
    }

    public obtenerFormacion(nombre: string) : any {
        let finded = this.formaciones.find(f => f.nombre == nombre);
        return finded;
    }

    public obtenerListaFormaciones() : Array<string> {
        return this.formaciones.map(f => f.nombre);
    }
}