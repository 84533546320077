import { v4 as uuidv4 } from 'uuid';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EnumeracionesService } from 'src/services/servicio.enumeraciones';
import { EquiposService } from 'src/services/servicio.equipos';
import { JugadoresService } from 'src/services/servicio.jugadores';
import { PartidosService } from 'src/services/servicio.partidos';
import { RecintosService } from 'src/services/servicio.recintos';
import { UbicacionesService } from 'src/services/servicio.ubicaciones';

@Component({
  selector: 'app-partido-editor',
  templateUrl: './partido-editor.component.html',
  styleUrls: ['./partido-editor.component.css']
})
export class PartidoEditorComponent implements OnInit {

  public id: string;
  public accion: string;
  public partido: any;
  public horario: any;
  public fechaSeleccionada: any;

  public jugadorAutenticado: any;

  public equiposLocalPosibles: Array<any>;
  public equiposVisitaPosibles: Array<any>;
  public recintosPosibles: Array<any>;
  public recintosRegiones: Array<any>;
  public recintosComunas: Array<any>;
  public recintosRegionSeleccionada: string;
  public recintosComunaSeleccionada: string;

  public equipoVisitaRegiones: Array<any>;
  public equipoVisitaComunas: Array<any>;
  public equipoVisitaRegionSeleccionada: string;
  public equipoVisitaComunaSeleccionada: string;

  public textoBusquedaVisita: string;
  public textoBusquedaRecinto: string;

  public superficiesPosibles: Array<any>;
  public opcionesCantidadJugadoresPosibles: Array<any>;

  public mensajesError: Array<string>;
  constructor(
    private partidoService: PartidosService,
    private jugadorService: JugadoresService,
    private recintosService: RecintosService,
    private ubicacionesService: UbicacionesService,
    private equiposService: EquiposService,
    private enumeracionesService: EnumeracionesService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.mensajesError = [];
    this.textoBusquedaRecinto = "";
    this.textoBusquedaVisita = "";
    this.recintosPosibles = [];
    this.inicializarUbicacionesEquipoVisita();
    this.inicializarUbicacionesRecinto();
    this.superficiesPosibles = this.enumeracionesService.obtenerSuperficies();
    this.opcionesCantidadJugadoresPosibles = this.enumeracionesService.obtenerOpcionesCantidadJugadores();
    this.route.params.subscribe(params => {
      this.accion = params['accion'];
      this.jugadorService.jugadorAutenticado().subscribe(res => {
        this.jugadorAutenticado = res.datos;

        this.equipoVisitaRegionSeleccionada = this.ubicacionesService.obtenerRegion(this.jugadorAutenticado.ubicacion.comuna).id;
        this.filtrarComunasEquipoVisita(this.equipoVisitaRegionSeleccionada);
        this.equipoVisitaComunaSeleccionada = this.jugadorAutenticado.ubicacion.comuna;
        
        this.recintosRegionSeleccionada = this.ubicacionesService.obtenerRegion(this.jugadorAutenticado.ubicacion.comuna).id;
        this.filtrarComunasRecinto(this.recintosRegionSeleccionada);
        this.recintosComunaSeleccionada = this.jugadorAutenticado.ubicacion.comuna;

        this.equiposLocalPosibles = [];
        if (this.jugadorAutenticado.contratos && this.jugadorAutenticado.contratos.length > 0) {
          let equipoIds = this.jugadorAutenticado.contratos.map(c => c.equipoId);
          this.equiposService.getMany(equipoIds).subscribe(res => {
            this.equiposLocalPosibles = res.datos;
            if (this.equiposLocalPosibles && this.equiposLocalPosibles.length > 0) {
              if (this.accion == "crear") {
                this.id = uuidv4();
                this.partido = {
                  horario: new Date(),
                  local: this.equiposLocalPosibles[0],
                  visita: {},
                  estadio: {},
                  jugadoresPorEquipo: 7,
                  superficie: 2,
                  invitaciones: [],
                  acciones: [],
                  comentarios: [],
                  estaTerminado: false,
                  id: this.id,
                  esVisible: true
                };
                this.horario = { hora: this.partido.horario.getHours(), minuto: this.partido.horario.getMinutes() };
                this.fechaSeleccionada = this.datePipe.transform(this.partido.horario,'yyyy-MM-dd');
              }

              if (this.accion == "modificar") {
                this.id = params['id'];
                this.partidoService.getPartido(this.id).subscribe(res => {
                  if (res && res.esCorrecto && res.datos) {
                    this.partido = res.datos;
                    
                    let HHmm = this.datePipe.transform(this.partido.horario,'HH:mm').split(':');

                    this.horario = { hora: parseInt(HHmm[0]), minuto: parseInt(HHmm[1]) };
                    this.fechaSeleccionada = this.datePipe.transform(this.partido.horario,'yyyy-MM-dd');
                  }
                  else {
                    alert("No se puede encontrar el partido");
                  }
                });
              }
            }
            else {
              alert("Primero debe crear un equipo");
            }
          });
        }
      });
    });
  }

  inicializarUbicacionesRecinto() : void {
    this.recintosRegiones = this.ubicacionesService.obtenerRegiones().filter(region => region.id != 99);
    this.filtrarComunasRecinto(this.recintosRegiones[0].id);
  }

  inicializarUbicacionesEquipoVisita()  : void {
    this.equipoVisitaRegiones = this.ubicacionesService.obtenerRegiones().filter(region => region.id != 99);
    this.filtrarComunasEquipoVisita(this.equipoVisitaRegiones[0].id);
  }

  validar() : boolean {
    this.mensajesError = [];

    if (!this.partido.estadio.id){
      this.mensajesError.push("No hay un recinto seleccionado");
    }

    if (!this.partido.visita.id){
      this.mensajesError.push("No hay un equipo visitante seleccionado");
    }
    
    if (this.mensajesError.length > 0){
      return false;
    }

    return true;
    
  }

  guardar(): void {
    let dia = this.fechaSeleccionada.split('-')[2]*1;
    let mes = this.fechaSeleccionada.split('-')[1]*1;
    let anio = this.fechaSeleccionada.split('-')[0]*1;

    let horario = new Date(anio, mes - 1, dia, this.horario.hora, this.horario.minuto, 0, 0);
    let horarioDate = this.datePipe.transform(horario, 'yyyy-MM-ddTHH:mm:ss');
    this.partido.horario = new Date(Date.parse(horarioDate));

    console.log(this.partido);

    if (this.validar()) {
      if (this.accion == 'crear') {
        this.partidoService.crear(this.partido).subscribe(res => {
          if (res.esCorrecto) {
            this.router.navigate(["/partido", this.id]);
          }
          else {
            alert("Hubo un error, vea la consola para ver el partido que falló");
            console.log(this.partido);
          }
        })
      }
      else {
        this.partido.ultimaModificacion = new Date();
        this.partidoService.actualizar(this.partido).subscribe(res => {
          if (res.esCorrecto) {
            this.router.navigate(["/partido", this.id]);
          }
          else {
            alert("Hubo un error, vea la consola para ver el partido que falló");
            console.log(this.partido);
          }
        })
      }
    }
  }

  seleccionarEquipoLocal(equipoId: any): void {
    let resultado = this.equiposLocalPosibles.find(e => e.id == equipoId);
    this.partido.local = resultado;
  }

  buscarVisita(): void {
    this.equiposService.getEquipos(this.textoBusquedaVisita.toLowerCase(), 1, 3).subscribe(res => {
      console.log(res);
      if (res.esCorrecto) {
        this.equiposVisitaPosibles = res.items;
      }
      else {
        alert("error");
      }
    });
  }

  buscarEquipoVisitaPorComuna(): void {
    this.equiposService.getEquiposPorComuna(this.equipoVisitaComunaSeleccionada, 1, 3).subscribe(res => {
      console.log(res);
      if (res.esCorrecto) {
        this.equiposVisitaPosibles = [ ...[this.equipoDesconocido()] , ...res.items ];
      }
      else {
        alert("error");
      }
    });
  }

  buscarRecinto(): void {
    this.recintosService.getRecintosPorComuna(this.recintosComunaSeleccionada, 1, 3).subscribe(res => {
      console.log(res);
      if (res.esCorrecto) {
        this.recintosPosibles = res.items;
      }
      else {
        alert("error");
      }
    });
  }

  filtrarComunasRecinto(regionId: any) : void {
    console.log(regionId);
    this.recintosComunas = [];
    let regiones = this.ubicacionesService.obtenerRegiones();
    for (let indiceRegiones=0; indiceRegiones < regiones.length; indiceRegiones++) {
      if (regiones[indiceRegiones].id == regionId) {
        this.recintosRegionSeleccionada = regionId;
        let provincias = regiones[indiceRegiones].ubicaciones;
        for (let indiceProvincias=0; indiceProvincias < provincias.length; indiceProvincias++) {
          let comunas = provincias[indiceProvincias].ubicaciones;
          for (let indiceComunas=0; indiceComunas < comunas.length; indiceComunas++) {
            if (indiceComunas == 0){
              this.recintosComunaSeleccionada = comunas[indiceComunas].id;
            }
            this.recintosComunas.push(comunas[indiceComunas]);
          }
        }
        this.recintosComunas.sort((a, b) => a.nombre.localeCompare(b.nombre));
        break;
      }
    }
  }

  filtrarComunasEquipoVisita(regionId: any) : void {
    console.log(regionId);
    this.equipoVisitaComunas = [];
    let regiones = this.ubicacionesService.obtenerRegiones();
    for (let indiceRegiones=0; indiceRegiones < regiones.length; indiceRegiones++) {
      if (regiones[indiceRegiones].id == regionId) {
        this.equipoVisitaRegionSeleccionada = regionId;
        let provincias = regiones[indiceRegiones].ubicaciones;
        for (let indiceProvincias=0; indiceProvincias < provincias.length; indiceProvincias++) {
          let comunas = provincias[indiceProvincias].ubicaciones;
          for (let indiceComunas=0; indiceComunas < comunas.length; indiceComunas++) {
            if (indiceComunas == 0){
              this.equipoVisitaComunaSeleccionada = comunas[indiceComunas].id;
            }
            this.equipoVisitaComunas.push(comunas[indiceComunas]);
          }
        }
        this.equipoVisitaComunas.sort((a, b) => a.nombre.localeCompare(b.nombre));
        break;
      }
    }
  }

  fechaCambiada(event: any): void {
    this.fechaSeleccionada = event.target.value;
    console.log(this.fechaSeleccionada);
  }

  horarioCambiado(): void {
    //this.establecerHorarioEnUTC();
  }

  retornarHorarioEnUTC(original: Date): Date {
    return new Date(
      original.getUTCFullYear(),
      original.getUTCMonth(),
      original.getUTCDate(),
      original.getUTCHours(),
      original.getUTCMinutes(),
      0
    );
  }

  equipoDesconocido() : any {
    let equipoDesconocido = {
      nombre: "Desconocidos",
      emblema: "Desconocidos",
      urlLogo: "",
      urlEquipo: "",
      color: "#FF0000",
      colorAlternativo: "#FFFFFF",
      alias: "Desconocidos",
      ubicacion: {
        latitud: -34.170967,
        longitud: -70.7284,
        direccion: "lala #123",
        comuna: "06-1-16"
      },
      nomina: [
        {
          id: "00000000-0000-0000-0000-000000000000",
          jugador: {
              alias: null,
              estatura: 0,
              peso: 0,
              posicion: 0,
              ubicacion: null,
              contratos: null,
              partidos: null,
              nombre: null,
              apellido: null,
              email: null,
              telefono: null,
              fechaNacimiento: "2023-05-29T01:21:41.754677Z",
              id: "00000000-0000-0000-0000-000000000000",
              esVisible: false,
              ultimaModificacion: "2023-05-29T01:21:41.746489Z",
              keywords: []
          },
          numeroCamiseta: 69,
          esCapitan: true,
          estado: 0,
          posicion: 0,
          timestamp: "2023-05-29T01:21:41.761665Z"
        }
      ],
      fechaInscripcion: "2001-02-10T00:00:00Z",
      partidos: [],
      id: "00000000-0000-0000-0000-000000000000",
      esVisible: false,
      ultimaModificacion: "2023-05-29T01:21:41.775111Z",
      keywords: []
    }
    return equipoDesconocido;
  }
}
