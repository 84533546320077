import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormacionesService } from 'src/services/servicio.formaciones';

@Component({
  selector: 'app-formacion-partido-equipo',
  templateUrl: './formacion-partido-equipo.component.html',
  styleUrls: ['./formacion-partido-equipo.component.css']
})
export class FormacionPartidoEquipoComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('formacionEquipo') public canvasFormacionEquipo: ElementRef;

  canvasEl: any;
  ctx: CanvasRenderingContext2D;

  @Input() public Width = 300;

  @Input() public Height = 400;

  @Input()
  public nomina: Array<any>;

  @Input()
  public formacion: string;

  constructor(private formacionesService: FormacionesService) { 
    this.formacion = '';
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (this.ctx) {
      this.pintarFormacion();
    }
  }

  ngOnInit(): void {
  }

  pintarFormacion() : void {
    if (this.formacion != '' && this.nomina) {
      let formacion = this.formacionesService.obtenerFormacion(this.formacion);
      let elementosPintables = [];
      formacion.posiciones.forEach(posicion => {
          let jugador = posicion.index < this.nomina.length ? this.nomina[posicion.index] : null;
          let nuevoElemento = {};
          if (jugador)
            nuevoElemento = { x: (this.Width * posicion.widthPerc / 100), y: (this.Height * posicion.heightPerc / 100), titulo: jugador.nombre, link: jugador.id };
          else
            nuevoElemento = { x: (this.Width * posicion.widthPerc / 100), y: (this.Height * posicion.heightPerc / 100), titulo: '', link: '' };
          elementosPintables.push(nuevoElemento);
      });
      this.pintar(elementosPintables);
    }
  }
  
  public ngAfterViewInit() {
    this.canvasEl = this.canvasFormacionEquipo.nativeElement;
    this.ctx = this.canvasEl.getContext('2d');

    this.canvasEl.width = this.Width;
    this.canvasEl.height = this.Height;

    this.pintarFormacion();
  }

  pintar(elementos: Array<any>) {
    this.ctx.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);//clear canvas
    elementos.forEach(elemento => {
      this.pintarCirculo(this.ctx, elemento.x, elemento.y + 20, 5, 'black', 'black', 2);
      this.pintarTexto(this.ctx, elemento.titulo, elemento.x, elemento.y)
    });
  }

  pintarCirculo(ctx, x, y, radius, fill, stroke, strokeWidth) : void {
    ctx.beginPath()
    ctx.arc(x, y, radius, 0, 2 * Math.PI, false)
    if (fill) {
      ctx.fillStyle = fill
      ctx.fill()
    }
    if (stroke) {
      ctx.lineWidth = strokeWidth
      ctx.strokeStyle = stroke
      ctx.stroke()
    }
  }

  pintarTexto(ctx, text, x, y) : void {
      ctx.fillStyle = "white";
      ctx.font = "16px Arial";
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(text, x, y);
  }
  
}
