import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PartidoMiniaturaComponent } from './partido-miniatura/partido-miniatura.component';
import { PosicionMiniaturaComponent } from './posicion-miniatura/posicion-miniatura.component';
import { InputHorarioComponent } from './input-horario/input-horario';
import { EquipoMiniaturaComponent } from './equipo-miniatura/equipo-miniatura.component';
import { SubirFicheroComponent } from './subir-fichero/subir-fichero.component';
import { MultimediaService } from 'src/services/servicio.multimedia';
import { AccionPartidoMiniaturaComponent } from './accion-partido-miniatura/accion-partido-miniatura.component';
import { EnumeracionesService } from 'src/services/servicio.enumeraciones';
import { FormacionPartidoEquipoComponent } from './formacion-partido-equipo/formacion-partido-equipo.component';
import { FormacionesService } from 'src/services/servicio.formaciones';

@NgModule({
  declarations: [PartidoMiniaturaComponent, PosicionMiniaturaComponent, InputHorarioComponent, EquipoMiniaturaComponent, SubirFicheroComponent, AccionPartidoMiniaturaComponent, FormacionPartidoEquipoComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule
  ],
  providers: [MultimediaService, EnumeracionesService, FormacionesService],
  exports: [PartidoMiniaturaComponent, PosicionMiniaturaComponent, InputHorarioComponent, EquipoMiniaturaComponent, SubirFicheroComponent, AccionPartidoMiniaturaComponent, FormacionPartidoEquipoComponent]
})
export class SharedModule { }
