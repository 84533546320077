import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnumeracionesService } from 'src/services/servicio.enumeraciones';
import { EquiposService } from 'src/services/servicio.equipos';
import { JugadoresService } from 'src/services/servicio.jugadores';
import { SesionService } from 'src/services/servicio.sesion';
import { UbicacionesService } from 'src/services/servicio.ubicaciones';

@Component({
  selector: 'app-mi-perfil',
  templateUrl: './mi-perfil.component.html',
  styleUrls: ['./mi-perfil.component.css']
})
export class MiPerfilComponent implements OnInit {
  perfil: any;
  equipos: Array<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private jugadoresService: JugadoresService,
    private equiposService: EquiposService,
    private ubicacionesService: UbicacionesService,
    private enumeracionesService: EnumeracionesService
  ) { }

  ngOnInit(): void {
    this.recargar();
  }

  public recargar() : void {
    this.jugadoresService.jugadorAutenticado().subscribe(res => {
      this.perfil = res.datos;
      console.log(this.perfil);
      this.equipos = [];
      if (this.perfil.contratos && this.perfil.contratos.length > 0){
        let equipoIds = this.perfil.contratos.map(c => c.equipoId);
        this.equiposService.getMany(equipoIds).subscribe(res => {
          this.equipos = res.datos;
          console.log(this.equipos);
        });
      }
    });
  }

  obtenerEquipo(id: string) : any {
    let equipos = this.equipos.filter(m => m.id == id);
    if (equipos.length == 0 || equipos.length > 1)
      return { nombre: "", id: "" };
    return equipos[0];
  } 


  obtenerPosicion(posicion: number): string {
    return this.enumeracionesService.obtenerPosicion(posicion);
  }

  calcularEdad(fechaNac: string) : number {
    let fechaNacimiento = new Date(fechaNac);
    let timeDiff = Math.abs(Date.now() - fechaNacimiento.getTime());
    let age = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);
    return age;
  }

  public obtenerUbicacion(ubicacionId: string) : any {
    let ubicacion = this.ubicacionesService.getUbicacion(ubicacionId);
    return ubicacion;
  }

  public esCapitan(equipo: any) : boolean {
    
    let items = equipo.nomina.find(itemNomina => itemNomina.jugador.id == this.perfil.id && itemNomina.esCapitan);
    console.log(equipo.nomina, this.perfil, items);
    if (items)
      return true;
    return false;
  }

  public renunciar(equipo: any) : void {
    debugger;
    let contrato = this.perfil.contratos.find(c => c.equipoId == equipo.id);
    console.log(contrato);
    this.jugadoresService.renunciar(contrato).subscribe(res => {
      console.log(res);
      this.recargar();
    });
  }

}
